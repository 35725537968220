import React, { useState, useEffect, useRef } from 'react';
import './Herostyle.css';
import { HeroContainer, GridC, ColumnC, IMG} from './HeroElements';

import DILogo from '../../pictures/DitreItalia/Website_Ditre Italia_Logo.jpg';
import MFLogo from '../../pictures/Miniforms/Miniforms_Logo.jpg';
import OALogo from '../../pictures/Oasis/Website_Oasis_Logo.png';
import LMLogo from '../../pictures/Laurameroni/LAURA-MERONI-LOGO-NEW.jpg';
import FBLogo from '../../pictures/Fatboy/Website_Fatboy_logo.jpg';

import { imagesDI } from '../../pictures/DitreItalia/Data.js';
import { imagesMF } from '../../pictures/Miniforms/Data.js';
import { imagesOA } from '../../pictures/Oasis/Data.js';
import { imagesLM } from '../../pictures/Laurameroni/Data.js';
import { imagesFB } from '../../pictures/Fatboy/Data';


const Hero = () => {
    const timerRefDF = useRef();
    const [countDF, setCountDF] = useState(0);

    const timerRefMF = useRef();
    const [countMF, setCountMF] = useState(0);

    const timerRefOA = useRef();
    const [countOA, setCountOA] = useState(0);

    const timerRefLM = useRef();
    const [countLM, setCountLM] = useState(0);

    const timerRefFB = useRef();
    const [countFB, setCountFB] = useState(0);
  
    useEffect(() => () => clearInterval(timerRefDF.current), []);
    useEffect(() => () => clearInterval(timerRefMF.current), []);
    useEffect(() => () => clearInterval(timerRefOA.current), []);
    useEffect(() => () => clearInterval(timerRefLM.current), []);
    useEffect(() => () => clearInterval(timerRefFB.current), []);

    useEffect(() => {
        window.scrollTo(0,0)
      },[])
  
    const updateCountDI = () => {
      timerRefDF.current = setInterval(() => {
        setCountDF((countDF) => countDF + 1);
      }, 1500);
    };
  
    const origCountDI = () => {
      clearInterval(timerRefDF.current);
      setCountDF(0);
    };

    const updateCountMF = () => {
        timerRefMF.current = setInterval(() => {
          setCountMF((countMF) => countMF + 1);
        }, 1500);
      };
    
      const origCountMF = () => {
        clearInterval(timerRefMF.current);
        setCountMF(0);
      };

      const updateCountOA = () => {
        timerRefOA.current = setInterval(() => {
          setCountOA((countOA) => countOA + 1);
        }, 1500);
      };
    
      const origCountOA = () => {
        clearInterval(timerRefOA.current);
        setCountOA(0);
      };

      const updateCountLM = () => {
        timerRefLM.current = setInterval(() => {
          setCountLM((countLM) => countLM + 1);
        }, 1500);
      };
    
      const origCountLM = () => {
        clearInterval(timerRefLM.current);
        setCountLM(0);
      };

      const updateCountFB = () => {
        timerRefFB.current = setInterval(() => {
          setCountFB((countFB) => countFB + 1);
        }, 1500);
      };
    
      const origCountFB = () => {
        clearInterval(timerRefFB.current);
        setCountFB(0);
      };
  return (
    <>
        <HeroContainer> 
            <GridC>
                <ColumnC>
                <div className="Imgeffects">
                    <a  href='https://www.ditreitalia.com/de/' target='_blank' rel="noreferrer">
                        <div onMouseOver={updateCountDI} onMouseOut={origCountDI} >
                            <IMG src={imagesDI[countDF % imagesDI.length].source} alt={imagesDI.name} />
                        </div>
                    </a>
                </div>
                </ColumnC>

                <ColumnC>
                <div className="Imgeffects">
                    <a href='https://www.ditreitalia.com/de/' target='_blank' rel="noreferrer"><IMG src={DILogo} /></a>
                </div>
                </ColumnC>

                <ColumnC>
                <div className="Imgeffects">
                    <a  href='https://www.miniforms.com/' target='_blank' rel="noreferrer">
                        <div onMouseOver={updateCountMF} onMouseOut={origCountMF} >
                            <IMG src={imagesMF[countMF % imagesMF.length].source} alt={imagesMF.name} />
                        </div>
                    </a>
                </div>
                </ColumnC>

                <ColumnC>
                <div className="Imgeffects">
                    <a href='https://www.miniforms.com/' target='_blank' rel="noreferrer"><IMG src={MFLogo} /></a>
                </div>
                </ColumnC>

                <ColumnC>
                <div className="Imgeffects">
                    <a  href='https://www.oasisgroup.it/de/' target='_blank' rel="noreferrer">
                        <div onMouseOver={updateCountOA} onMouseOut={origCountOA} >
                            <IMG src={imagesOA[countOA % imagesOA.length].source} alt={imagesOA.name} />
                        </div>
                    </a>
                </div>
                </ColumnC>

                <ColumnC>
                <div className="Imgeffects">
                    <a href='https://www.oasisgroup.it/de/' target='_blank' rel="noreferrer"><IMG src={OALogo} /></a>
                </div>
                </ColumnC>

                <ColumnC>
                <div className="Imgeffects">
                    <a  href='https://www.laurameroni.com/en' target='_blank' rel="noreferrer">
                        <div onMouseOver={updateCountLM} onMouseOut={origCountLM} >
                            <IMG src={imagesLM[countLM % imagesLM.length].source} alt={imagesLM.name} />
                        </div>
                    </a>
                </div>
                </ColumnC>

                <ColumnC>
                <div className="Imgeffects">
                    <a href='https://www.laurameroni.com/en' target='_blank' rel="noreferrer"><IMG src={LMLogo} /></a>
                </div>
                </ColumnC>

                <ColumnC>
                <div className="Imgeffects">
                    <a  href='https://www.fatboy.com/gb-en' target='_blank' rel="noreferrer">
                        <div onMouseOver={updateCountFB} onMouseOut={origCountFB} >
                            <IMG src={imagesFB[countFB % imagesFB.length].source} alt={imagesFB.name} />
                        </div>
                    </a>
                </div>
                </ColumnC>

                <ColumnC>
                <div className="Imgeffects">
                    <a href='https://www.fatboy.com/gb-en' target='_blank' rel="noreferrer"><IMG src={FBLogo} /></a>
                </div>
                </ColumnC>

                <ColumnC>
                    <div className='Footer'></div>
                </ColumnC>

                <ColumnC>
                    <div className='Footer'></div>
                </ColumnC>

                <ColumnC>
                    <div className='Footer'></div>
                </ColumnC>

            </GridC>
        </HeroContainer>
    </>
  )
}

export default Hero;
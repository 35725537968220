import React, {useState, useRef} from 'react';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import Video from '../../Movie/file.mp4';
import emailjs from '@emailjs/browser';
import { AboutUsContainer, Container, TopLine, VideoBg, StyledContactForm, TextContainer } from './ContactElements';
const Contact = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [MathQ, setMathQ] = useState(0);
    const [Solution] = useState(10);

    const form = useRef();

    const toggle = () => {
        setIsOpen(!isOpen);
    };


    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Ergebnis: " + Number(MathQ));
        if(Number(MathQ) === Solution){
            emailjs.sendForm(
                process.env.REACT_APP_SERVICE, 
                process.env.REACT_APP_TEMPLATE, 
                form.current, 
                process.env.REACT_APP_KEY)
              .then((result) => {
                  console.log(result.text);
              }, (error) => {
                  console.log(error.text);
              });
              form.current.reset();
          }else{
            alert('Wrong answer! Please try it again.')
          }
        }
  return (
    <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle}/>
        <Container>
            <TopLine>Contact us</TopLine>
            <AboutUsContainer>
                <div></div> 
                <TextContainer>
                    <div>Agentur Brettner</div>< br></br>
                    <div>Hauptstraße 42</div>< br></br>
                    <div>A-2641 Schottwien</div>< br></br>
                    <div>Tel: +43 699 10267209</div>< br></br>
                    <div>Mail: office@brettner.at</div>
                </TextContainer>
                <div><VideoBg autoPlay loop muted src={Video} type='video/mp4' /></div>
                <StyledContactForm>
                    <form ref={form} onSubmit={sendEmail}>
                        <label>Name</label>
                        <input type="text" name="user_name" />
                        <label>Email</label>
                        <input type="email" name="user_email" />
                        <label>Message</label>
                        <textarea name="message" />
                        <div>7+3=</div><input  onChange={e => setMathQ(e.target.value)}/>
                        <input type="submit" value="Send"/>
                    </form>
                </StyledContactForm>    
            </AboutUsContainer>
        </Container>
    </>
  )
}

export default Contact;
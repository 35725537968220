import React from 'react';
import { 
    SidebarContainer, 
    Icon, 
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink
} from './SidebarElements';
import { Link } from 'react-router-dom';

const Sidebar = ({isOpen, toggle}) => {

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="home" onClick={toggle}><Link to="/" className='LStyle'>Home</Link></SidebarLink>
                <SidebarLink to="about" onClick={toggle}><Link to="/about" className='LStyle'>About</Link></SidebarLink>
                <SidebarLink to="contact" onClick={toggle}><Link to="/contact" className='LStyle'>Contact</Link></SidebarLink>
                <SidebarLink to="impressum" onClick={toggle}><Link to="/Impressum" className='LStyle'>Impressum</Link></SidebarLink>
            </SidebarMenu>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar;
import styled from "styled-components";

export const FooterContainer = styled.div`
    position: absolute;
    display:flex;
    background-color:#DB0B0B;
    color: white;
    bottom: 0;
    left: 0;
    right: 0;
    height:100px;
    align-items: center;
    flex-direction: column;
    padding-top: 15px;
   `

   export const FooterItems = styled.h2`
    text-decoration: none;
   `
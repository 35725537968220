import IMG1 from './Website_Hauptfoto_soda_botera.jpg';
import IMG2 from './Websitefoto_brulla.png';
import IMG3 from './Websitefoto_Caruso_Mastea.jpg';
import IMG4 from './Websitefoto_colonyy_chap.jpg';
import IMG5 from './Websitefoto_Juice.jpg';
import IMG6 from './Websitefoto_middle-e-les-brothers.jpg';
import IMG7 from './Websitefoto_Ozz.jpg';

export const imagesMF = [
    { spec_id: "1", name: "Image 1", source: IMG1 },
    { spec_id: "2", name: "Image 2", source: IMG2 },
    { spec_id: "3", name: "Image 3", source: IMG3 },
    { spec_id: "4", name: "Image 4", source: IMG4 },
    { spec_id: "5", name: "Image 5", source: IMG5 },
    { spec_id: "6", name: "Image 6", source: IMG6 },
    { spec_id: "7", name: "Image 7", source: IMG7 }
  ];
  
import React, {useState, useEffect} from 'react';
import { FaBars } from 'react-icons/fa';
import { 
    Nav, 
    NavbarContainer, 
    NavLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks, 
} from './NavbarElements';
import { IconContext } from 'react-icons/lib'; 
import { animateScroll as scroll } from 'react-scroll';
import { Link } from 'react-router-dom';

const Navbar = ({
    toggle, 
  }) => {
    const [scrollNav, setScrollNav] = useState(false);
  
    const changeNav= () => {
      if (window.scrollY >= 80) {
        setScrollNav(true);
      } else {
        setScrollNav(false);
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', changeNav);
    }, []);
  
    const toggleHome = () => {
      scroll.scrollToTop();
    };
  
    return (
      <>
        <IconContext.Provider value={{ color: '#fff' }}>
          <Nav scrollNav={scrollNav}>
            <NavbarContainer>
              <NavLogo to="/" onClick={toggleHome}>
                Agentur Brettner
              </NavLogo>
              <MobileIcon onClick={toggle}>
                <FaBars />
              </MobileIcon>
              <NavMenu>
                {[
                  { to: '/', title: 'Home', }, 
                  { to: '/about', title: 'About', }, 
                  { to: '/contact', title: 'Contact', }, 
                  { to: '/Impressum', title: 'Impressum' }
                ].map(({ to, title }) => (
                  <NavItem key={to}>
                    <NavLinks 
                      to={to}
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                    >
                      {<Link to={to} className='LStyle'>{title}</Link>}
                     </NavLinks>
                  </NavItem>
                ))}
              </NavMenu>
            </NavbarContainer>
          </Nav>
        </IconContext.Provider>
      </>
    )
  }
  
  export default Navbar;

import styled from "styled-components";

export const Container = styled.div`
    padding-top: 80px;
    padding-left: 2rem;
`

export const TopLine = styled.h1`
    color: red;
    justify-content: left;
    margin-top:2rem;
`
import React, {useState} from 'react';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import { AboutUsContainer, Container, Img, TopLine, TextContainer } from './AboutElements';
import img1 from '../../pictures/Schottwien4.jpeg';

const About = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

  return (
    <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle}/>
        <Container>
            <TopLine>About us</TopLine>
            <AboutUsContainer>
                <Img src={img1} />
                <TextContainer>
                    <div>Schottwien 1968: Erich Brettner gründet in dem winzigen Ort im südlichen NÖ das Unternehmen BRETTNER MÖBEL.</div>< br></br>
                    <div>Über 40 Jahre werden in der hauseigenen Tischlerei Qualitätsmöbel gefertigt.</div>< br></br>
                    <div>Im Jahre 2000: Seine Tochter Christine übernimmt nach einer abgeschlossenen Ausbildung der HTL Mödling Abt. Möbel-und Innenausbau den elterlichen Betrieb und strukturiert ihn Jahre später um.</div>< br></br>
                    <div>Aus der Möbeltischlerei wird eine MÖBELAGENTUR. Durch ihre Affinität zu Italien wählt sie vor allem italienische Möbelfirmen und führt deren Vertrieb für ganz Österreich.</div>< br></br>
                    <div>Seit 2017: Verstärkung durch Veronika Wölff, ebenso aus der Interior-Ecke HTL für Möbelbau & Innenausbau Imst doch bringt sie auch vielschichtige Erfahrungen u.a. aus den Bereichen Administration, Kundenservice und Kommunikation mit ein.</div>
                </TextContainer>
            </AboutUsContainer>
        </Container>
    </>
  )
}

export default About;
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home/Home';
import Impressum from './components/Impressum/Impressum';
import About from './components/About/About';
import Contact from './components/Contact/Contact';

const App = () => {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} exact/>
          <Route path="/Impressum" element={<Impressum />} exact/>
          <Route path="/About" element={<About />} exact/>
          <Route path='/Contact' element={<Contact />} exact/>
        </Routes>
      </Router>
  );
}

export default App;

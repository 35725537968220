import React, {useState} from 'react';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import { Container, TopLine } from './ImpressumElements';

const Impressum = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle}/>
        <Container>
            <TopLine>Impressum</TopLine><br />
            <ul>
                <h3>Verantwortlich für den Inhalt dieser Seiten:</h3><br />
                <div>Information gemäß §5 E-Commerce-Gesetz und Offenlegung gemäß § 25 Mediengesetz</div><br />
                <h4>Diensteanbieter und Medieninhaber:</h4>
                <div>Christine Doppelreiter-Brettner</div>
                <div>Hauptstraße 42, A-2641 Schottwien</div>
                <div>Telefon: +43 699 10267209</div>
                <div>https://brettner.at</div>
                <div>office@brettner.at</div>
            </ul>
        </Container>    
    </>
  )
}

export default Impressum;
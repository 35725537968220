import React from 'react';
import './LStyle.css';
import { FooterContainer } from './FooterElements';

const Footer = () => {
  return (
    <>
        <FooterContainer>
            <div>Agentur Brettner</div> 
            <div>A-2641 Schottwien</div>
            <div>Hauptstr. 82</div>
            <div>+43 699 10267209</div>
        </FooterContainer>
    </>
  );
};

export default Footer;

import styled from "styled-components";

export const HeroContainer = styled.div`
    //background-color: #FFF;
    position: relative; //new
    margin-top: 80px;
    @media screen and (max-width: 768px) {
        padding: 100px 0;
    }
`

export const GridC = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  //background-color: rgba(255,0,0,.7);
  height: 100%;
  margin: 0;
  padding: 0;
  position:relative;

  @media screen and (max-width: 3000px){ // change, solved error on iphone rotation
    grid-template-columns: 70% 30% //old: repeat(2, 1fr)
  }

  @media screen and (max-width: 844px){ // change, solved error on iphone rotation
    grid-template-columns: 70% 30%  85 //old: repeat(2, 1fr)
  }
  
  @media screen and (max-width: 768px){
    grid-template-columns:  70% 30% 
  }
  
  @media screen and (max-width: 480px){
    grid-template-columns: repeat(1, 1fr)
  }
`

export const ColumnC = styled.div`
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  //background-color: rgba(0,0,0,.2);
  justify-content: center;
  align-items: center;
  display: flex; 
  padding: 0;
  margin: 0;
  max-width: 1170px;
`

export const IMGContainer = styled.div`
  width: 50%;
  max-width: 300px;
`

export const IMG = styled.img`

  opacity: 1;
  display: block;
  width: 100%;
  height: -webkit-fill-available;
  transition: .5s ease;
  backface-visibility: hidden;

`

export const OnverL = styled.div`
  left: 0;
  bottom: 100%;
  height: 0;
  width: 100%;
  overflow: hidden;
  backdrop-filter: blur(8px) brightness(80%);
  transition: all .3s ease-in-out;
`

export const overlay_4 = styled.div`
  left: 0;
  bottom: 100%;
  height: 0;
  width: 100%;
  overflow: hidden;
  backdrop-filter: blur(8px) brightness(80%);
  transition: all .3s ease-in-out;
`

export const image_wrapper = styled.li`
  bottom: 0;
  height: 100%;

  &:hover .overlay_4 {
    
  }


`

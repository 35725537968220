import styled from "styled-components";

export const Container = styled.div`
    padding-top: 80px;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
`
export const TopLine = styled.h1`
    color: red;
    justify-content: left;
    margin-top:2rem;
`

export const AboutUsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 35px;
    height: 100%;
    margin: 0;
    padding: 0;
    position:relative;
    
    @media screen and (max-width: 480px){
        grid-template-columns: repeat(1, 1fr)
    }
`

export const TextContainer = styled.div`
width: 100%;
font-size: 20px;
color: black;
`

export const Img = styled.img`
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: bottom;
`
export const VideoBg = styled.video`
    width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`

export const ContactUsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    margin: 0;
    padding: 0;
    position:relative;
    
    @media screen and (max-width: 480px){
        grid-template-columns: repeat(1, 1fr)
    }
`

export const StyledContactForm = styled.div`
  width: 100%;
  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }
    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }
    label {
      margin-top: 1rem;
    }
    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: #DB0B0B;
      color: white;
      border: none;
    }
  }
`;
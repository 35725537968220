import IMG1 from './Website_Hauptfoto.jpg'
import IMG2 from './Website_outdoor_Fatboy.jpg'
import IMG3 from './Websitefoto_edison.jpg'
import IMG4 from './Websitefoto_floatzac.jpg'
import IMG5 from './Websitefoto_green indoor.jpg'
import IMG6 from './Websitefoto_rocknroll.jpeg'

export const imagesFB = [
    { spec_id: "1", name: "Image 1", source: IMG1 },
    { spec_id: "2", name: "Image 2", source: IMG2 },
    { spec_id: "3", name: "Image 3", source: IMG3 },
    { spec_id: "4", name: "Image 4", source: IMG4 },
    { spec_id: "5", name: "Image 5", source: IMG5 },
    { spec_id: "6", name: "Image 6", source: IMG6 }
  ];
  
import React, {useState} from 'react';
import Hero from '../HeroSection/Hero';
import Footer from '../Footer/Footer';
import Sidebar from '../Sidebar/index.js';
import Navbar from '../Navbar/index.js';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle}/>
        <Hero />
        <Footer />
    </>
  )
}

export default Home;
import IMG1 from './Websitefoto_Hauptfoto.jpg';
import IMG2 from './Websitefoto_Elements_Sideboard_Lampe.jpg';
import IMG3 from './Websitefoto_Anrichte_und_Lampe.jpg';
import IMG4 from './Website_mood.jpg';
import IMG5 from './Website_Kitchen.jpg';
import IMG6 from './Websitefoto_Tisch.jpg';

export const imagesLM = [
    { spec_id: "1", name: "Image 1", source: IMG1 },
    { spec_id: "2", name: "Image 2", source: IMG2 },
    { spec_id: "3", name: "Image 3", source: IMG3 },
    { spec_id: "4", name: "Image 4", source: IMG4 },
    { spec_id: "5", name: "Image 5", source: IMG5 },
    { spec_id: "6", name: "Image 6", source: IMG6 },
  ];
  